<template>
    <v-main>
        <link href="https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap" rel="stylesheet">
    <v-container>
        <div class="hidden-sm-and-down">
        <v-row
        no-gutters
        class='flex-column flex-md-row flex-column-reverse'
        >
            <v-col
            md="6"
            class="ml-auto"            
            >
                <h1>Devis</h1>
                <p class="text-justify">
                Les devis sont gratuits et s'il ne le sont pas pour les raisons qui suivent, cela sera explicitement dit avant d'entreprendre ce dernier. 
                <br>
                <br>
                Le devis n'est pas gratuit si:
                <ul>
                    <li>Le devis demandé nécessite plusieurs heures de travail. Dans ce cas, il est possible que des frais de devis soient réclamés au moment de sa demande. Ils représentent une franchise de 65€ HTVA. </li>
                    <li>Le chantier se situe en dehors du Brabant-Wallon ou à plus de 40km de notre zone de travail. Ici, seul des frais de déplacements seront demandés lors du premier contact.</li>
                    <li>Il n'est simplement pas chiffrable. Certains chantiers sont difficilement chiffrables, ces cas sont rares et concernent principalement des travaux dont l'accès ou la visibilité des réparations à effectuer ne sont pas adéquates. </li>
                </ul>
                <br>
                Comme écrit sur ces derniers, ils sont valable 14 jours après réception et peuvent être modifiés avec l'accord du client si le produit ou service n'est plus disponible au moment prévu des travaux. Pour plus d'information veuillez consulter <router-link :to="'conditions'">les conditions générales de vente</router-link>.
                <br>
                Pour tout éventuelle exception à ces règles, le devis ou la facture sera tenu comme seule et véritable preuve. Si un délai ou une ristourne à été accordée, il faut qu'elle soit explicitement écrite sur le devis ou la facture.
                </p>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
            md="5"
            class="ml-auto"
            flex-column flex-md-column flex-column-reverse
            >
            <div class="container">
                <div>
                    <h1>Commande</h1>
                    <p class="text-justify">
                    Une fois le devis signé, il sera convenu d'une date ainsi que d'un éventuel acompte en fonction du prix total de la commande. Cet acompte représente 30% du prix total et sera soumis à une facture payable dans le délai impartit. Cet acompte fera office de validation de la commande.
                    </p>
                </div>

                <br>

                <div>
                    <h1>Coordonnées</h1>
                    <strong>SF ELEC</strong>
                    <br>
                    <strong>IBAN:</strong> BE47 0013 9323 8480
                    <br>
                    <strong>BIC:</strong> GEBABEBB
                    <br>
                    <strong>TVA:</strong> BE 0741 978 635
                    <br>
                    <strong>Siège social:</strong> Venelle Bruyère Sainte Anne, 3 - 1300 Wavre
                </div>
            </div>

                <br><br><br>

                <v-img
                    :src="require('.././assets/ampouleseule.png')"
                    class="my-3"
                    contain
                    height="100"
                />                    
            </v-col>    
        </v-row>
        </div>

<!-- mobile -->
        <div class="hidden-md-and-up">
            <v-row
            no-gutters
            class='flex-column flex-md-row flex-column-reverse'
            >
                <v-col
                md="6"
                class="ml-auto "            
                >
                <div class="container">
                    <h1 class="h1-mobile">Devis</h1>
                    <p class="text-justify">
                    Les devis sont gratuits et s'il ne le sont pas pour les raisons qui suivent, cela sera explicitement dit avant d'entreprendre ce dernier. 
                    <br>
                    <br>
                    Le devis n'est pas gratuit si :
                    <ul>
                        <li>Le devis demandé nécessite plusieurs heures de travail. Dans ce cas, il est possible que des frais de devis soient réclamés au moment de sa demande. Ils représentent une franchise de 65€ HTVA. </li>
                        <li>Le chantier se situe en dehors du Brabant-Wallon ou à plus de 40km de notre zone de travail. Ici, seul des frais de déplacements seront demandés lors du premier contact.</li>
                        <li>Il n'est simplement pas chiffrable. Certains chantiers sont difficilement chiffrables, ces cas sont rares et concernent principalement des travaux dont l'accès ou la visibilité des réparations à effectuer ne sont pas adéquates. </li>
                    </ul>
                    <br>
                    Comme écrit sur ces derniers, ils sont valable 14 jours après réception et peuvent être modifiés avec l'accord du client si le produit ou service n'est plus disponible au moment prévu des travaux. Pour plus d'information veuillez consulter <router-link :to="'conditions'">les conditions générales de vente</router-link>.
                    <br>
                    Pour tout éventuelle exception à ces règles, le devis ou la facture sera tenu comme seule et véritable preuve. Si un délai ou une ristourne à été accordée, il faut qu'elle soit explicitement écrite sur le devis ou la facture.
                    </p>
                </div>

                    <br><br>

                    <v-img
                        :src="require('.././assets/ampouleseule.png')"
                        class="my-3"
                        contain
                        height="100"
                    />  
                </v-col>

                <v-spacer></v-spacer>

                <v-col
                md="5"
                class="ml-auto"
                flex-column flex-md-column flex-column-reverse
                >
                <div class="container">
                    <h1>Coordonnées</h1>
                    <strong>SF ELEC</strong>
                        <br>
                    <strong>IBAN:</strong> BE47 0013 9323 8480
                        <br>
                    <strong>BIC:</strong> GEBABEBB
                        <br>
                    <strong>TVA:</strong> BE 0741 978 635
                        <br>
                    <strong>Siège social:</strong> Venelle Bruyère Sainte Anne, 3 - 1300 Wavre
                </div>

                <div class="container">
                    <h1 class="h1-mobile">Commande</h1>
                    <p class="text-justify">
                    Une fois le devis signé, il sera convenu d'une date ainsi que d'un éventuel acompte en fonction du prix total de la commande. Cet acompte représente 30% du prix total et sera soumis à une facture payable dans le délai impartit. Cet acompte fera office de validation de la commande.
                    </p>   
                </div>                                     
                </v-col>    
            </v-row>
        </div>
    </v-container>
    </v-main>
</template>

<script>
export default {
    
}
</script>

<style scoped>
h1{
  font-family: 'Jura', sans-serif;
  font-weight: bold;  
  color:#3D6F9D;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: 959px) {
    h1{
        margin-top: 70px;
    }

    .h1-mobile{
        margin-top: 30px;
    }
}
</style>